<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách cơ cấu tổ chức hợp lệ</h4>
      </div>
      <div class="listwork__table__01">
        <!-- ===============================================bắt dầu bảng nhóm cơ cấu tổ chức hợp lệ-------------------------------- -->
        <vue-good-table
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          :columns="columns"
          :rows="dataInvalid || []"
          style-class="vgt-table"
        >
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm cơ cấu tổ chức hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} cơ cấu tổ chức</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách cơ cấu tổ chức không hợp lệ</h4>
        </div>
        <!-- ================================================nút chỉnh sửa  -------------------------------- -->
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- ================================================nút kiểm tra  -------------------------------- -->
          <div class="listwork__head__02__right__icon">
            <b-button
              v-b-tooltip.hover.top="'Kiểm tra'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>

          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <!-- ===============================================bắt dầu bảng nhóm cơ cấu tổ chức không hợp lệ-------------------------------- -->
        <vue-good-table
          ref="constuctor-table"
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              :id="'tooltip' + props.row.originalIndex"
              v-b-tooltip.hover.right.html="props.row.messageError"
              variant="outline-danger"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <!--======================= Mã cơ cấu tổ chức lỗi =================================== -->
            <span v-if="props.column.field == 'code' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Code'? 'text-danger' :'' "
              >{{ props.row.code }}</span>
            </span>

            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>

            <!--======================= Tên cơ cấu tổ chức lỗi =================================== -->
            <span v-if="props.column.field == 'name' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Name'? 'text-danger' :'' "
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <!--======================= Tên cơ cấu tổ chức lỗi =================================== -->
            <span v-if="props.column.field == 'parentId' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'ParentId'? 'text-danger' :'' "
              >{{ props.row.parentId }}</span>
            </span>

            <span v-if="props.column.field == 'parentId' && editvalueErr === true ">
              <organization-select
                v-if="props.row.messErr[0].location === 'ParentId'"
                v-model="props.row.parentIdName"
                class="cssInput"
                @input="changeCellvalue(props.row.parentIdName, 'parentIdName', props.row)"
                @checkNameText="checkNameText"
              />
              <span
                v-if="props.row.messErr[0].location !== 'ParentId'"
              >{{ props.row.parentId }}</span>
            </span>

            <span v-if="props.column.field == 'description'">
              <span>{{ props.row.description }}</span>
            </span>

          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm cơ cấu tổ chức không  hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} cơ cấu tổ chức</p>
      </div>

      <div class="listwork__button">
        <!-- ================================================nút thêm nhóm cơ cấu tổ chức-------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addUser"
          >
            Thêm cơ cấu tổ chức
          </b-button>
        </div>
        <!-- ================================================nút hủy bỏ  -------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeExcel"
          >
            Hủy bỏ
          </b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BButton, BBreadcrumb, BBreadcrumbItem, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import OrganizationSelect from '../../../users/pages/components/OrganizationSelect.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VBTooltip,
    OrganizationSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã cơ cấu tổ chức',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên cơ cấu tổ chức',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Cơ cấu tổ chức cha',
          field: 'parentId',
          sortable: false,
        },
        {
          label: 'Mô tả',
          field: 'description',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
          sortable: false,
        },
        {
          label: 'Mã cơ cấu tổ chức',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên cơ cấu tổ chức',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Cơ cấu tổ chức cha',
          field: 'parentId',
          sortable: false,
        },
        {
          label: 'Mô tả',
          field: 'description',
          sortable: false,
        },
      ],
      textNameParent: [],
    }
  },
  computed: {
    ...mapGetters('struct', ['dataValidUser', 'dataNoValidUser', 'dataTooltip']),
  },
  created() {
    this.dataInvalid = this.dataValidUser
    this.dataNoInvalid = this.dataNoValidUser
    this.checkErrorUser(this.dataTooltip)
  },
  methods: {
    ...mapActions('struct', ['getApiExcelStruct']),

    checkNameText(value, data) {
      this.textNameParent.push(value)
      this.dataNoInvalid.forEach(element => {
        this.textNameParent.forEach(ele => {
          if (element.parentIdName === ele.id) element.parentId = ele.name
        })
      })
    },

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    async CheckdataNoInvalid() {
      this.checkNameText(this.dataNoInvalid)
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcelStruct(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    addUser() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.getApiExcelStruct(model).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'list-struct' })
          }
        } else {
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },

    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },

    closeExcel() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'list-struct' })
    },
  },
}
</script>

<style lang='scss' scoped>
.listwork {
  background: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.listwork__top {
  padding: 20px;
}
.listwork__top h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #181f28;
}
.listwork__text__center p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  margin-top: 20px;
  padding-right: 20px;
}
.listwork__head__02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.listwork__head__02__left h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.listwork__head__02__right {
  display: flex;
  align-items: center;
}
.listwork__head__02__right__icon {
  margin-left: 20px;
}

.listwork__button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item {
  margin-left: 20px;
}

/* Breadcrumb  */
.header {
  display: flex;
  margin-bottom: 32px;
}
.header-title {
  height: 26px;
  font-weight: 500;
  font-size: 21px;
  line-height: 25, 6px;
  padding-right: 20px;
  position: relative;
  color: #2e3a4a;
}
.header-title::after {
  content: "";
  display: block;
  position: absolute;

  right: 0;
  top: 0;

  width: 1px;
  height: 26px;
  background-color: #cbcdd2;
}
.header-breadcrumb {
  font-size: 14px;
  height: 21px;
  line-height: 21px;
  display: flex;
  align-content: center;
  padding: 0;
}
a {
  color: #227ff4;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #2e3a4a;
}
[dir] .breadcrumb {
  padding-left: 20px;
}
/* end breadcrumb */

.text-danger {
  color: red;
}
.cssInput {
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
</style>
